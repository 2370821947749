import {
    getPersona,
    listPersonas
} from './queries';
import {
    createPersona,
    deletePersona,
    updatePersona,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreatePersona,
    DeletePersona,
    GetPersona,
    ListPersonas,
    Persona,
    UpdatePersona
} from "../types.ts";
import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";
import {generateClient} from "aws-amplify/api";
import {config} from "../config";

const client = generateClient();

export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListPersonas>>({
                                                                              query: listPersonas,
                                                                              variables: {filter: filter},
                                                                          });
        return response.data?.listPersonas.items;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPersona>>({
                                                                            query: getPersona,
                                                                            variables: {id: id},
                                                                        });
        return response.data?.getPersona;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const add = async (payload: Persona) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreatePersona>>({
                                                                               query: createPersona,
                                                                               variables: {
                                                                                   input: payload
                                                                               },
                                                                           });
        console.debug('in the service response', response);

        showSuccessNotification(`${config.titles.singular} created`);
        return response.data?.createPersona;
    } catch (e: any) {

        if (e.errors) {
            // filter out all other messages but the transaction cancelled exception
            e.errors = e.errors.filter((error: {
                errorType: string
            }) => error.errorType === 'DynamoDB:TransactionCanceledException')

            // iterate over the errors and replace the message if it is a duplicate
            e.errors.forEach((error: { errorType: string, message: string }) => {
                if (error.errorType === 'DynamoDB:TransactionCanceledException') {
                    error.message = 'Duplicate persona. please select another'
                }
            });
        }

        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: Persona) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePersona>>({
                                                                               query: (updatePersona),
                                                                               variables: {input: record},
                                                                           });

        showSuccessNotification(`${config.titles.singular} updated`);
        return response.data?.updatePersona;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string, email: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePersona>>({
                                                                               query: (deletePersona),
                                                                               variables: {
                                                                                   input: {
                                                                                       id: id,
                                                                                       email: email,
                                                                                   }
                                                                               },

                                                                           });
        showSuccessNotification(`${config.titles.singular} deleted`);
        return response.data?.deletePersona;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
