import {NavLink} from 'react-router-dom';

import {
    Box,
    Breadcrumbs,
} from '@mantine/core';
import {Persona} from '../types';
import {config} from "../config";

export const EditPageNavigation = ({component}: { component: Persona }) => {

    const breadcrumbs = [
        {
            title: 'Home',
            href: '/dashboard',
        }, {
            title: config.titles.plural,
            href: `/personas`,
        }, {
            title: [component.givenName, component.familyName].join(' ')
        }
    ].map((item, index) => (
        <Box key={index}> {
            item
                .href ?
                <NavLink to={item.href}>{item.title}</NavLink> : item.title
        }
        </Box>
    ));

    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );
};
