import {
    getMessage,
    listMessages
} from './queries';
import {
    createMessage,
    deleteMessage,
    updateMessage,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreateMessage,
    DeleteMessage,
    GetMessage,
    ListMessages,
    Message,
    UpdateMessage
} from "../types.ts";
import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";
import {generateClient} from "aws-amplify/api";
import {config} from "../config";

const client = generateClient();


export const fetchRecords = async (
    {
        aliasId,
        tagId,
        status
    }: {
        aliasId?: string
        tagId?: string,
        status?: string
    }) => {

    let filter = {}

    if (typeof aliasId !== 'undefined' && aliasId !== null) {
        filter = {
            ...filter,
            ...{aliasId: aliasId}
        };
    }

    if (typeof tagId !== 'undefined' && tagId !== null) {
        filter = {
            ...filter,
            ...{tagIds: [tagId]}
        }
    }

    if (typeof status !== 'undefined' && status !== null) {
        filter = {
            ...filter,
            ...{status: status}
        };
    }

    console.debug('IN FETCH RECORDS. FILTER', filter);

    try {
        const response = await client.graphql<GraphQLQuery<ListMessages>>({
                                                                              query: listMessages,
                                                                              variables: {
                                                                                  filter: filter
                                                                              },
                                                                          });
        return response.data?.listMessages.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetMessage>>({
                                                                            query: getMessage,
                                                                            variables: {id: id},
                                                                        });
        return response.data?.getMessage;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateMessage>>({
                                                                               query: createMessage,
                                                                               variables: {input: payload},
                                                                           });
        console.debug('in the service response', response);

        showSuccessNotification(`${config.titles.singular} created`);
        return response.data?.createMessage;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: Message) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;
    delete record.tags;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateMessage>>({
                                                                               query: (updateMessage),
                                                                               variables: {input: record},
                                                                           });

        showSuccessNotification(`${config.titles.singular} updated`);
        return response.data?.updateMessage;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteMessage>>({
                                                                               query: (deleteMessage),
                                                                               variables: {input: {id: id}},

                                                                           });
        showSuccessNotification(`${config.titles.singular} deleted`);
        return response.data?.deleteMessage;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
