import {I18n} from '@aws-amplify/core';

import {SimplePageHeader,} from '@flexinet/ui-components';
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {AddRecordButton} from '../buttons/AddRecordButton.tsx';
import {ReloadRecordsButton} from '../buttons/ReloadRecordsButton.tsx';
import {DeleteRecordButton} from '../buttons/DeleteRecordButton.tsx';
import {config} from "../config";


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ReloadRecordsButton key='reload'/>];

    if (allowedActions.includes('DeleteAlias')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes('CreateAlias')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get(config.titles.plural)}
            tools={useTools()}
        />
    );
};
